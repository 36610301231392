import {AjaxFormComponent} from "ui-base/src/Components/Form/_resources/ts/AjaxFormComponent";
import {ComponentLoader} from "../../../../../../AbstractComponent";

export class LoginForm extends AjaxFormComponent {
    public static selector: string = 'login-form-component';
    public errorCodes = '{"ERROR_1001":"That email is not available.","ERROR_1002":"CSRF or formKey fields are missing from post object","ERROR_1003":"Invalid CSRF token","ERROR_1004":"Request Post Object is empty","ERROR_1010":"There was a problem with your username or password, please try again","ERROR_1011":"Invalid Data Provided"}';

    protected onResponseSuccess(data) {
        data = JSON.parse(data);

        if (data.success) {
            if (data.returnURI != undefined) {
                window.location.href = data.returnURI;
                return;
            }
            return;
        }

        if (!data.success) {
            if (data.errorCode != undefined) {
                let jsonErrorCodes = JSON.parse(this.errorCodes);
                let errorMessage = jsonErrorCodes["ERROR_" + data.errorCode];
                this.displayErrorMessage(errorMessage);

            }
        }

        this.setButtonToProcessing(false);

    }

}

new ComponentLoader(LoginForm);
